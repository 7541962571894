<template>
  <div id="app">
    <componetnt :is="layout">
      <router-view />
    </componetnt>
  </div>
</template>
<script>
const default_layout = "default";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || default_layout) + "-layout";
    },
  },
};
</script>




