import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueParallaxJs from 'vue-parallax-js'
import VueLazyload from 'vue-lazyload'
import Default from './layouts/Default.vue'
import Full from './layouts/Full.vue'
import VueImg from 'v-img';

Vue.use(VueImg);

const loadimage = require('./assets/loading.gif')
const errorimage = require('./assets/error.gif')

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: errorimage,
  loading: loadimage,
  attempt: 1,
  lazyComponent: true
})

Vue.component('default-layout', Default);
Vue.component('full-layout', Full);

Vue.config.productionTip = false
Vue.use(VueParallaxJs)

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
