<template>
  <div class="home">
    <div class="main-image-container">
      <transition name = "fade">
      <img class="main-image" :src="image" :key="imageIndex" style="width: 100%" />
      </transition>
      <div class="main-filter"></div>
      <div class="main-logo"><h1>hm-design</h1></div>
      <div class="main-centered">
        <p class="center-text">
          Návrhy interiérů - navrhneme jakoukoliv část. Je nám jedno, jestli jde
          o hrubou stavbu, byt, rodinný dům, SPA či kancelář. Rádi Vám poradíme
          se vším!
        </p>
        <router-link class="main-button" to="/gallery">Galerie</router-link>
        <router-link class="main-button" to="/about">Zjistit více</router-link>
      </div>
    </div>
  </div>
</template>


<script>
const axios = require("axios").default;

export default {
  name: "Home",
  data() {
    return {
      carousel: null,
      image: "/data/gallery/mezonetovybyt/mezonetovybyt(12).JPG",
      imageIndex: 0,
    };
  },
  mounted() {
    let $vm = this;

    axios
      .get("/data/carousel/carousel.json")
      .then(function (response) {
        //loadData(response);
        $vm.carousel = response.data;
        setInterval($vm.start, 15000);
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        // always executed
      });
  },
  methods: {
    start: function () {
      this.imageIndex++;
      if (this.imageIndex >= this.carousel.images.length) {
        this.imageIndex = 0;
      }
      this.image = this.carousel.images[this.imageIndex];
    },
  },
};
</script>
<style>
   .fade-enter-active {
      transition: opacity 5s
   }
   .fade-leave-active{
     transition: opacity 2s;
     position: absolute;
   }
   .fade-enter, .fade-leave-to {
      opacity: 0
   }
</style>